<template>
  <Item :item="item" :parent="parent">
    <HorizontalLineItem
      :item="item.items[0]"
      :parent="item"
      class="-my-6 mx-auto"
    />
  </Item>
</template>

<script>
// import { get } from 'vuex-pathify'
import blockMixin from '@/mixins/block'
import itemMixin from '@/mixins/item'

export default {
  name: 'HorizontalLine',

  components: {
    Item: () => import('@/components/Item'),
    HorizontalLineItem: () => import('@/components/Items/HorizontalLine'),
  },

  mixins: [blockMixin, itemMixin],

  computed: {},
}
</script>
